import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AccountFinderDistrict} from './AccountFinderDistrict';
import {AccountFinderViewProps, filterVisible} from './AccountFinderView';
import {User} from '../../users/user.class';

interface AccountFinderDivisionProps extends AccountFinderViewProps {
    accountKeys: string[];
    accountOnlyMode: boolean;
    selectAccount: (accountNum: string, contactID: string, returnUrlParam?: string) => void;
    setAccountKeys: Dispatch<SetStateAction<string[]>>;
    type?: `parent` | `division` | `district`;
    user: User;
}

export const AccountFinderDivision = ({
    account,
    accountKeys,
    accountOnlyMode,
    selectAccount,
    setAccountKeys,
    type,
    user,
}: AccountFinderDivisionProps) => {
    return (
        <div className={account.hidden ? `d-none` : undefined}>
            <div className={`d-flex justify-content-between ${type === 'parent' ? 'gray-200-bg' : 'gray-150-bg'} border-top p-3`}>
                <div className="d-flex align-items-center">
                    {type !== 'parent' && (
                        <div className="mr-3">
                            {account.accounts.filter(({hidden}) => !hidden).length > 0 && (
                                <FontAwesomeIcon
                                    icon={accountKeys.indexOf(account.account) >= 0 ? faAngleDown : faAngleUp}
                                    size="xl"
                                    className="pointer"
                                    onClick={() => {
                                        if (accountKeys.indexOf(account.account) === -1) {
                                            setAccountKeys([...accountKeys, account.account]);
                                        } else {
                                            setAccountKeys(accountKeys.filter((accountKey) => accountKey !== account.account));
                                        }
                                    }}
                                />
                            )}
                        </div>
                    )}
                    <div className="d-flex flex-column">
                        <div className="h5 font-weight-bold mb-0 text-capitalize">{account.name?.toLowerCase()}</div>
                        <div className="caption">
                            {account.account} {account.hidden}
                        </div>
                    </div>
                </div>
                <div>
                    {user.activeAccount === account.account && <>Logged In</>}
                    {user.activeAccount !== account.account && !accountOnlyMode && (
                        <button
                            className="btn btn-secondary text-nowrap col-auto"
                            onClick={() => {
                                selectAccount(account.account, account.contact);
                            }}
                        >
                            Log In
                        </button>
                    )}
                </div>
            </div>
            <div>
                {account.accounts &&
                    account.accounts.filter(filterVisible()).map(
                        (district) =>
                            district.type === `District` && (
                                <AccountFinderDistrict
                                    account={district}
                                    accountKeys={accountKeys}
                                    accountOnlyMode={accountOnlyMode}
                                    division={account.account}
                                    key={district.account}
                                    selectAccount={selectAccount}
                                    setAccountKeys={setAccountKeys}
                                    user={user}
                                />
                            ),
                    )}
            </div>
        </div>
    );
};

import {TemplateHelpers} from '../tools/template-helpers.class';

/**
 * Filters items by filterValue
 * @param data
 * @param filterValue
 * @param fields
 */
export const filterItems = (data: any[], filterValue: string, fields: string[]): any[] => {
    const filteredValue = `${TemplateHelpers.regexSafeFilterVal(filterValue.toLowerCase())}`;
    for (const item of data) {
        item.hidden = fields.map((key) => item[key].toString().toLowerCase().indexOf(filteredValue) > -1).indexOf(true) === -1;
    }
    return data;
};

/**
 * TBD
 * @param data
 * @param filterValue
 */
export const filterStrings = (data: string[], filterValue: string): string[] => {
    const strings: string[] = [];
    if (filterValue) {
        const filteredValue = `${TemplateHelpers.regexSafeFilterVal(filterValue.toLowerCase())}`;
        for (const item of data) {
            strings.push(item.toString().toLowerCase().indexOf(filteredValue) > -1 && item);
        }
        return strings;
    } else {
        return data;
    }
};

/**
 * TBD
 * @param data
 * @param filterValue
 * @param sort
 * @param fields
 */
export const sortAndFilter = <T>(data: T[], filterValue: string, sort: string, fields: string[]): T[] =>
    sortItems(filterItems(data, filterValue, fields), sort);

/**
 * TBD
 * @param data
 * @param sort
 */
export const sortItems = <T>(data: T[], sort: string): T[] => {
    const [key, dir] = sort.split(`_`);
    const type = dir === `0` || dir === `9` ? `number` : `string`;
    return data.sort((a, b) => {
        if (type === `number`) {
            return dir === `0` ? a[key] - b[key] : b[key] - a[key];
        }
        return dir === `a` ? a[key]?.localeCompare(b[key]) : b[key]?.localeCompare(a[key]);
    });
};

/**
 * Sort Numbers
 * @param data
 * @param sortField
 */
export const sortNumbers = <T>(data: T[], sortField: string): T[] => {
    return data.sort((a, b) => {
        return a[sortField] - b[sortField];
    });
};

/**
 * Sort strings
 * @param data
 * @param sortField
 */
export const sortStrings = <T>(data: T[], sortField: string): T[] => {
    return data.sort((a, b) => {
        return a[sortField]?.localeCompare(b[sortField]);
    });
};

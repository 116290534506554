import styled from '@emotion/styled';
export const FilterInputContainer = styled.div`
    .input-group {
        flex-wrap: nowrap;

        .filter-input {
            flex: 1 1 auto !important;
            padding: 18px 0 18px;
            border-radius: 0;
            height: 52px;
            font-size: 1rem;
            text-indent: 8px;
        }

        .input-group-text {
            height: 52px;
        }
    }
`;
